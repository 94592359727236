<template>
<div class="d-inline col-md-2 m-0 p-0">
    <button type="button" id="search-button-agent"
    class="btn btn-primary w-100 rounded-0 d-print-none" data-toggle="modal" data-target="#search-modal-agent"><i class="fa fa-search"></i>受注</button>
    <!-- モーダル部分 -->
    <div class="modal fade" id="search-modal-agent" tabindex="-1" role="dialog" aria-labelledby="search-modal-label-agent" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <!-- モーダルヘッダー -->
                <div class="modal-header">
                    <h5 class="modal-title" id="label1">受注一覧</h5>
                    <!-- 閉じるボタン -->
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <!-- モーダルボディ -->
                <div class="modal-body" >
                    <div>
                        <!-- 検索フォーム -->
                        <!-- <div class="form-row">
                            <div class="form-group col-md-2">
                                <label for="search-agent-agent_cd" class="">AGENT CD</label>
                                <input id="search-agent-agent_cd" class="form-control" type="text" name="search.agent_cd" v-model="agent_cd">
                            </div>
                            <div class="form-group col-md-2">
                                <label for="search-agent-agent_name" class="">AGENT NAME</label>
                                <input id="search-agent-agent_name" class="form-control" type="text" name="search.agent_name" v-model="agent_name">
                            </div>
                        </div>
                        <div class="form-row mb-2">
                                <div class="ml-auto">
                                    <button type="button" class="btn btn-primary px-3 pt-2 mr-2" @click="search(1);"><h5 class="mb-0">SEARCH</h5></button>
                                    <button type="button" class="btn btn-primary px-3 pt-2 mr-2" @click="clear();"><h5 class="mb-0">CLEAR</h5></button>
                                </div>
                        </div> -->

                        <!-- 検索フォーム -->
                        <div class="row justify-content-around">
                            <div class="mb-5 card card-body bg-light col-md-7">
                                <div class="form-group row">
                                    <div class="col-md-12 h3">検索</div>
                                </div>
                                <div class="form-group row">
                                    <label for="m_merchandise.name" class="text-right col-md-2">納期</label>
                                    <div class="input-group col-md-7 px-0">
                                        <!-- <input type="date" name="delivery_date_start" class="common-form-input form-control datetimepicker-input date datetimepicker" id="delivery_date_start" value="{{ $search['delivery_date_start'] ?? now()->firstOfMonth()->format('Y-m-d') }}"> -->
                                        <input v-model="delivery_date_start" type="date" name="delivery_date_end" class="form-control datetimepicker-input date datetimepicker" id="delivery_date_end" value="">
                                        <div class="input-group-append input-group-prepend">
                                            <span class="input-group-text  form-control">～</span>
                                        </div>
                                        <!-- <input type="date" name="delivery_date_end" class="common-form-input form-control datetimepicker-input date datetimepicker" id="delivery_date_end" value="{{<input type="date" name="delivery_date_end" class="common-form-input form-control datetimepicker-input date datetimepicker" id="delivery_date_end" value=""> $search['delivery_date_end'] ?? now()->lastOfMonth()->format('Y-m-d') }}"> -->
                                        <input v-model="delivery_date_end" type="date" name="delivery_date_end" class="form-control datetimepicker-input date datetimepicker" id="delivery_date_end" value="">
                                    </div>
                                    <div class="col-md-3">
                                        <button type="button" class="form-control btn btn-primary btn-block"
                                        v-on:click="search(1)">
                                            <i class="fas fa-search"></i>　検索
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="m_merchandise.standard" class="col-md-2 text-right">販売先</label>
                                    <select v-model="customer_id" type="text" class="form-control col-md-7" id="customer_id" name="customer_id">
                                        <option value="">選択してください</option>
                                        <option
                                        v-for="customer in customer_list"
                                        :key="customer.customer_id"
                                        :value="customer.customer_id">
                                        {{ customer.customer_name }}</option>
                                        <!-- @foreach(App\Models\Master\Customer::all() as $value)
                                        <option value="{{ $value->customer_id }}" @if(($search['customer_id'] ?? '') == $value->customer_id) selected @endif>{{ $value->customer_name }}</option>
                                        @endforeach -->
                                    </select>
                                    <div class="col-md-3">
                                        <button type="button" class="form-control btn btn-secondary btn-block"
                                        v-on:click="clear()">
                                            <i class="fas fa-eraser"></i>　クリア
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 一覧 -->
                        <!-- Selectがクリックされた時の処理はBlade側のjQueryで記述 -->
                        <!-- <div class="table-responsive mb-4" style="height:500px;">
                            <table class="table table-bordered table-sm table-striped table-hover scrollTable screen">
                                <thead class="thead-dark">
                                    <tr>
                                        <th class="">AGENT CD</th>
                                        <th class="">AGENT NAME</th>
                                        <th class="">SELECT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in items" :key="item.id" name="agentList">
                                        <td class="">{{item.agent_cd}}</td>
                                        <td class="">{{item.agent_name}}</td>
                                        <td><button type="button" class="btn btn-success selectbtn-agent" data-dismiss="modal" :value="JSON.stringify(item)">SELECT</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> -->

                        <!-- 一覧 -->
                        <table class="table table-striped table-hover table-bordered">
                            <thead class="thead-dark">
                            <tr class=" text-center">
                                <th scope="col">案件コード</th>
                                <th scope="col">販売先</th>
                                <th scope="col">現場名</th>
                                <th scope="col">納期</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                                <!-- @foreach($items as $item)
                                <tr class="common-row" data-json="{{ $item }}">
                                    <td class="">{{ $item->matter_code }}</td>
                                    <td>{{ $item->customer->customer_name }}</td>
                                    <td>{{ $item->customer_detail->place }}</td>
                                    <td>{{ $item->delivery_date_start->format('m-d') }}<br>{{ $item->delivery_date_end->format('m-d') }}</td>
                                    <td><a href="{{ route('received.order.edit',$item->order_id) }}" class="btn btn-success" role="button"><i class="fas fa-edit"></i>　編集</a></td>
                                    <td><a href="{{ route('shipping.regist',$item->order_id) }}" class="btn btn-primary" role="button"><i class="fas fa-truck"></i>　配送登録</a></td>
                                    <td><a href="#" class="btn btn-danger" role="button"><i class="fas fa-ban"></i>　取り消し</a></td>
                                </tr>
                                @endforeach -->

                                <tr v-for="item in items" :key="item.id" class="" data-json="">
                                    <td>{{item.matter_code}}</td>
                                    <td>{{item.customer.customer_name}}</td>
                                    <td>{{item.customer_detail.place}}</td>
                                    <td>{{item.delivery_date_start | moment('YYYY/MM/DD')}}<br>{{item.delivery_date_end | moment('YYYY/MM/DD')}}</td>
                                    <!-- Selectがクリックされた時の処理はBlade側のjQueryで記述 -->
                                    <!-- <td><a href="" class="btn btn-success" role="button"><i class="fas fa-edit"></i>選択</a></td> -->
                                    <td><button type="button" class="btn btn-secondary selectbtn-matter-code" data-dismiss="modal" :value="JSON.stringify(item)"><i class="fas fa-hand-pointer"></i>　選択</button></td>
                                </tr>
                            </tbody>
                        </table>

                        <!-- ページネーション -->
                        <nav>
                            <ul class="pagination">
                                <!-- First Page View -->
                                <li v-if="currentPage == 1" class="page-item disabled">
                                    <span class="page-link" aria-hidden="true">&laquo;</span>
                                </li>
                                <li v-else class="page-item">
                                    <a @click.prevent.stop="search(1)" href="#" class="page-link">&laquo;</a>
                                </li>
                                <!-- Previous Page Link -->
                                <li v-if="currentPage == 1" class="page-item disabled" aria-disabled="true">
                                    <span class="page-link" aria-hidden="true">&lsaquo;</span>
                                </li>
                                <li v-else class="page-item">
                                    <a @click.prevent.stop="search(currentPage-1)" href="#" class="page-link" rel="prev">&lsaquo;</a>
                                </li>
                                <template v-for="(pageNumber,index) in pagerCounter">
                                    <!-- Array Of Links -->
                                    <li :key="index" v-if="currentPage == pageNumber" class="page-item active" aria-current="page">
                                        <span class="page-link">{{ pageNumber }}</span>
                                    </li>
                                    <li :key="index" v-else class="page-item">
                                        <a @click.prevent.stop="search(pageNumber)" href="#" class="page-link">{{ pageNumber }}</a>
                                    </li>
                                </template>
                                <!-- Next Page Link -->
                                <li v-if="currentPage == lastPage" class="page-item disabled">
                                    <span class="page-link" aria-hidden="true">&rsaquo;</span>
                                </li>
                                <li v-else class="page-item" aria-disabled="true">
                                    <a @click.prevent.stop="search(currentPage+1)" href="#" class="page-link" rel="next">&rsaquo;</a>
                                </li>
                                <!-- Last Page Link -->
                                <li v-if="currentPage == lastPage" class="page-item disabled">
                                    <span class="page-link" aria-hidden="true">&raquo;</span>
                                </li>
                                <li v-else class="page-item" aria-disabled="true">
                                    <a @click.prevent.stop="search(lastPage)" href="#" class="page-link">&raquo;</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import moment from "moment";
export default {
    name:'SearchOrderComponent',
    data:function(){
        return {
            // 検索条件
            delivery_date_start:null,
            delivery_date_end:null,
            customer_id:null,
            page:null,
            // Searchボタン、ページャーリンクで使用するURL
            url:this.propUrl,
            // 販売先プルダウンで使用する値
            customer_list:this.customerList,
            // 検索結果
            items:Array,
            // ページャー設定
            link_count:this.linkCount, // ページャーリンクの表示数
            item_count:this.itemCount, // 1ページに表示する行数
            // ページャー変数
            result:Array,
            start_page:null, // ページ開始番号
            end_page:null, // ページ終了番号
            currentPage:null, // 現在のページ番号
            lastPage:null, // 最終ページ番号
            pagerCounter:null, // ページャーの数字部分のリンクを生成するためのオブジェクト
        }
    },
    // インスタンス作成後、DOM作成前に実行されるメソッド
    created(){
        this.search(1);
    },
    props:{
        propUrl:null,
        customerList:null,
        itemCount:null,
        linkCount:null,
    },
    filters: {
        /**
         * @param {Date} value    - Date オブジェクト
         * @param {string} format - 変換したいフォーマット
         */
        moment(value, format) {
        return moment(value).format(format);
        }
    },
    // 関数はこの中で定義
    methods:{
        // 非同期検索
        search:function(page_number){
            console.log('search ok');
            axios.get(this.url, {
                params: {
                    delivery_date_start:this.delivery_date_start,
                    delivery_date_end:this.delivery_date_end,
                    customer_id:this.customer_id,
                    page:page_number,
                    item_count:this.item_count
                }
            }).then(response => {
                this.result = response.data.result; // ページャーオブジェクト
                this.items = this.result.data;  // 検索結果データ
                this.currentPage = this.result.current_page; // 現在のページ番号
                this.lastPage = this.result.last_page; // 最終ページ番号f
                this.displayPageRange();console.log('displayPageRange ok');
                this.initPagerCounter();console.log('initPagerCounter ok');
            }).catch((error) => {
                console.log('search error :'+error);
            });
        },
        // ページャーの表示数制御処理
        //start_page,end_pageの値をセット
        displayPageRange:function(){
            // link_countよりもページ数が多い時
            if(this.lastPage > this.link_count){
                // 現在ページが表示するリンクの中心位置よりも左の時
                if(this.currentPage <= Math.floor(this.link_count / 2)){
                    this.start_page = 1;
                    this.end_page = this.link_count;
                // 現在ページが表示するリンクの中心位置よりも右の時
                } else if(this.currentPage > this.lastPage - Math.floor(this.link_count / 2)){
                    this.start_page = this.lastPage - (this.link_count - 1);
                    this.end_page = this.lastPage;
                // 現在ページが表示するリンクの中心位置の時
                } else {
                    this.start_page = this.currentPage - (Math.floor((this.link_count % 2 == 0 ? this.link_count - 1 : this.link_count)  / 2));
                    this.end_page = this.currentPage + Math.floor(this.link_count / 2);
                }
            // link_countよりもページ数が少ない時
            } else {
                this.start_page = 1;
                this.end_page = this.lastPage;
            }
        },
        // pagerCounter変数の初期化
        initPagerCounter:function(){
            this.pagerCounter = {};
            for (var i = this.start_page; i <= this.end_page; i++) {
                this.pagerCounter[i] = i;
            }
        },
        // フォームのクリア処理
        clear:function(){
            this.delivery_date_start = '';
            this.delivery_date_end = '';
            this.customer_id = '';
        }
    }
}
// 受注一覧検索画面の「Select」が押されたときの処理
$(document).on('click', '.selectbtn-matter-code', function(){
    var item = JSON.parse($(this).val());
    $('#matter_code').val(item.matter_code);
    // TODO ここから　受注のデータをｺﾋﾟｰし表示
    $('#matter_code').val(item.matter_code);
});
</script>
