<template>
    <div>

<div class="form-row">
        <!-- 追加ボタン 最初の要素のみ -->
        <button type="button" class="btn btn-primary col-md-2 offset-md-8 d-print-none rounded-0"
        v-if="order_kbn != 3"
        v-on:click="append_order_detail()"
        >
        <i class="fas fa-box"></i>　追加
        </button>
        <!-- tes -->
          <label for="" class="text-center border border-dark d-block mb-0 p-2 col-md-8 offset-md-2">契約数量</label>
          <label for="" class="text-center border border-dark d-block mb-0 p-1 col-md-4 offset-md-2">品種</label>
          <label for="" class="text-center border border-dark d-block mb-0 p-1 col-md-2">数量</label>
          <label for="" class="text-center border border-dark d-block mb-0 p-1 col-md-2">重量</label>
        </div>

        <!-- 注文詳細 商品名,数量部分-->
        <div class="mb-5">

            <!-- 商品名,数量 行 編集時にデータがある場合はその数だけループ -->
            <div class="form-row"
            v-for="(order_detail_elem,order_detail_index) in order_detail_list"
            v-bind:key="order_detail_index"
            >
                <!-- 注文詳細ID (隠しパラメータ) -->
                <input type="hidden" maxlength="6"
                v-bind:id="'order_detail_id_'+order_detail_index"
                v-bind:name="'t_order_detail['+order_detail_index+'][order_detail_id]'"
                v-bind:aria-describedby="'validation_server_order_detail_id_'+order_detail_index"
                v-model="order_detail_list[order_detail_index]['order_detail_id']"
                />

                <!-- 注文ID (隠しパラメータ) -->
                <input type="hidden" maxlength="6"
                v-bind:id="'order_id_'+order_detail_index"
                v-bind:name="'t_order_detail['+order_detail_index+'][order_id]'"
                v-bind:aria-describedby="'validation_server_order_id_'+order_detail_index"
                v-model="order_detail_list[order_detail_index]['order_id']"
                />

                <!-- 商品セレクトボックス -->
                <select class="form-control border-dark rounded-0 col-md-4 offset-md-2"
                v-bind:id="'merchandise_id_'+order_detail_index"
                v-bind:name="'t_order_detail['+order_detail_index+'][merchandise_id]'"
                v-bind:aria-describedby="'validation_server_merchandise_id_'+order_detail_index"
                v-model="order_detail_list[order_detail_index]['merchandise_id']"
                v-on:change="select_merchandise(order_detail_index)"
                >
                <option value="">選択してください</option>
                <option
                v-for="merchandise in merchandise_list"
                v-bind:key="merchandise.merchandise_id"
                v-bind:value="merchandise.merchandise_id"
                >{{ merchandise.name2 }}
                </option>
                </select>

                <!-- 数量 -->
                <input type="text" class="form-control text-right border-dark rounded-0 col-md-2 number3" maxlength="6"
                v-bind:id="'quantity_'+order_detail_index"
                v-bind:name="'t_order_detail['+order_detail_index+'][quantity]'"
                v-bind:aria-describedby="'validation_server_quantity_'+order_detail_index"
                v-model="order_detail_list[order_detail_index]['quantity']"
                v-on:keyup="validate_number(order_detail_index,null,'quantity')"
                v-on:blur="onblured(order_detail_index,null,'quantity')"
                v-on:focus="onfocused(order_detail_index,null,'quantity')"
                v-on:change="calc_q2w(order_detail_index)"
                />

                <!-- 重量 -->
                <div class="input-group col-md-2 px-0">
                <input type="text" class="form-control text-right border-dark rounded-0 number3" maxlength="6"
                v-bind:id="'weight_'+order_detail_index"
                v-bind:name="'t_order_detail['+order_detail_index+'][weight]'"
                v-bind:aria-describedby="'validation_server_weight_'+order_detail_index"
                v-model="order_detail_list[order_detail_index]['weight']"
                v-on:keyup="validate_number(order_detail_index,null,'weight')"
                v-on:blur="onblured(order_detail_index,null,'weight')"
                v-on:focus="onfocused(order_detail_index,null,'weight')"
                v-on:change="calc_w2q(order_detail_index)"
                />
                <div class="input-group-append border border-dark">
                    <span class="input-group-text p-1" id="basic-addon2">{{order_detail_list[order_detail_index]['unit_kbn'] == 2 ? 't' : 'kg'}}</span>
                </div>
                </div>
                <!-- 削除ボタン 2つめ以降の要最のみ-->
                <button type="button" class="btn btn-danger col-md-2 d-print-none rounded-0"
                v-if="order_kbn != 3"
                v-on:click="remove_order_detail(order_detail_index)"
                >
                <i class="fas fa-trash"></i>　削除
                </button>
            </div>
        </div>

        <!-- 注文詳細 編集時にデータがある場合はその数だけループ -->
        <div class="form-row mb-5"
        v-for="(order_detail_elem,order_detail_index) in order_detail_list"
        v-bind:key="order_detail_index"
        >
            <!-- 経費追加ボタン -->
            <button type="button" class="btn btn-primary col-md-2 offset-md-8 d-print-none rounded-0"
            v-if="order_kbn != 3"
            v-on:click="append_t_expense(order_detail_index,order_detail_elem)"
            >
            <i class="fas fa-box"></i>　経費追加
            </button>

            <label for="" class="text-center border border-dark d-block mb-0 p-2 col-md-3 offset-md-2">
               {{ order_detail_list[order_detail_index]['m_weight'] }}kg詰
            </label>
            <label for="" class="text-center border border-dark d-block mb-0 p-1 col-md-5">
                (円/{{order_detail_list[order_detail_index]['unit_name']}}) {{ (order_detail_list[order_detail_index]['merchandise_name'] || '' ) + ' ' + (order_detail_list[order_detail_index]['twine'] || '') }}
            </label>

            <label for="" class="text-center border border-dark d-block mb-0 p-1 col-md-3 offset-md-2">条件</label>
            <input type="text" class="form-control border-dark rounded-0 col-md-5" id="" value="大原配達"/>

            <label for="" class="text-center border border-dark d-block mb-0 p-1 col-md-3 offset-md-2">設定価格</label>
            <input type="text" class="form-control border-dark rounded-0 col-md-5 text-right number3" maxlength="6"
            v-bind:id="'set_price_'+order_detail_index"
            v-bind:name="'t_order_detail['+order_detail_index+'][set_price]'"
            v-bind:aria-describedby="'validation_server_set_price_'+order_detail_index"
            v-model="order_detail_list[order_detail_index]['set_price']"
            v-on:keyup="validate_number(order_detail_index,null,'set_price')"
            v-on:blur="onblured(order_detail_index,null,'set_price')"
            v-on:focus="onfocused(order_detail_index,null,'set_price')"
            />

            <label for="" class="text-center border border-dark d-block mb-0 p-1 col-md-3 offset-md-2">口銭</label>
            <input type="text" class="form-control border-dark rounded-0 col-md-5 text-right number3" maxlength="6"
            v-bind:id="'fare_'+order_detail_index"
            v-bind:name="'t_order_detail['+order_detail_index+'][fare]'"
            v-bind:aria-describedby="'validation_server_fare_'+order_detail_index"
            v-model="order_detail_list[order_detail_index]['fare']"
            v-on:keyup="validate_number(order_detail_index,null,'fare')"
            v-on:blur="onblured(order_detail_index,null,'fare')"
            v-on:focus="onfocused(order_detail_index,null,'fare')"
            />

            <label for="" class="text-center border border-dark d-block mb-0 p-1 col-md-3 offset-md-2">運賃見合い</label>
            <input type="text" class="form-control border-dark rounded-0 col-md-5 text-right number3" maxlength="6"
            v-bind:id="'freight_'+order_detail_index"
            v-bind:name="'t_order_detail['+order_detail_index+'][freight]'"
            v-bind:aria-describedby="'validation_server_freight_'+order_detail_index"
            v-model="order_detail_list[order_detail_index]['freight']"
            v-on:keyup="validate_number(order_detail_index,null,'freight')"
            v-on:blur="onblured(order_detail_index,null,'freight')"
            v-on:focus="onfocused(order_detail_index,null,'freight')"
            />

            <label for="" class="text-center border border-dark d-block mb-0 p-1 col-md-3 offset-md-2">仕入価格</label>
            <input type="text" class="form-control border-dark rounded-0 col-md-5 text-right number3" maxlength="6"
            v-bind:id="'purchase_price_'+order_detail_index"
            v-bind:name="'t_order_detail['+order_detail_index+'][purchase_price]'"
            v-bind:aria-describedby="'validation_server_purchase_price_'+order_detail_index"
            v-model="order_detail_list[order_detail_index]['purchase_price']"
            v-on:change="calc_net_income(order_detail_index)"
            v-on:keyup="validate_number(order_detail_index,null,'purchase_price')"
            v-on:blur="onblured(order_detail_index,null,'purchase_price')"
            v-on:focus="onfocused(order_detail_index,null,'purchase_price')"
            />

            <!-- 経費 -->
            <div class="form-row col-12 p-0 m-0"
            v-for="(t_expense_elem,t_expense_index) in t_expense_list[order_detail_index]"
            v-bind:key="t_expense_index"
            >
                <!-- 経費ID (隠しパラメータ) -->
                <input type="hidden" class="form-control border-dark rounded-0 col-md-5"
                v-bind:id="'expense_id_'+order_detail_index+'_'+t_expense_index"
                v-bind:name="'t_expense['+order_detail_index+']['+t_expense_index+'][expense_id]'"
                v-bind:aria-describedby="'validation_server_expense_id_'+order_detail_index+'_'+t_expense_index"
                v-model="t_expense_list[order_detail_index][t_expense_index]['expense_id']"
                />

                <!-- 注文ID (隠しパラメータ) -->
                <input type="hidden" class="form-control border-dark rounded-0 col-md-5"
                v-bind:id="'order_id_'+order_detail_index+'_'+t_expense_index"
                v-bind:name="'t_expense['+order_detail_index+']['+t_expense_index+'][order_id]'"
                v-bind:aria-describedby="'validation_server_order_id_'+order_detail_index+'_'+t_expense_index"
                v-model="t_expense_list[order_detail_index][t_expense_index]['order_id']"
                />

                <!-- 注文詳細ID (隠しパラメータ) -->
                <input type="hidden" class="form-control border-dark rounded-0 col-md-5"
                v-bind:id="'order_detail_id_'+order_detail_index+'_'+t_expense_index"
                v-bind:name="'t_expense['+order_detail_index+']['+t_expense_index+'][order_detail_id]'"
                v-bind:aria-describedby="'validation_server_order_detail_id_'+order_detail_index+'_'+t_expense_index"
                v-model="t_expense_list[order_detail_index][t_expense_index]['order_detail_id']"
                />

                <!-- 印刷表示用 -->
                <label class="text-center border border-dark mb-0 p-1 col-md-3 offset-md-2 d-none d-print-inline">
                    {{ t_expense_list[order_detail_index][t_expense_index]['expense_name'] }}
                </label>

                <!-- 経費マスタID -->
                <select class="form-control border-dark text-center rounded-0 col-md-3 offset-md-2 d-print-none"
                v-bind:id="'m_expense_id_'+order_detail_index+'_'+t_expense_index"
                v-bind:name="'t_expense['+order_detail_index+']['+t_expense_index+'][m_expense_id]'"
                v-bind:aria-describedby="'validation_server_m_expense_id_'+order_detail_index+'_'+t_expense_index"
                v-model="t_expense_list[order_detail_index][t_expense_index]['m_expense_id']"
                v-on:change="select_expense(order_detail_index,t_expense_index)"
                >
                    <option value="">選択してください</option>
                    <option
                    v-for="(m_expense_elem,m_expense_index) in m_expense_list"
                    v-bind:key="m_expense_index"
                    v-bind:value="m_expense_elem['expense_id']"
                    >{{ m_expense_elem['expense_name'] }}
                    </option>
                </select>

                <!-- 金額 -->
                <input type="text" class="form-control border-dark rounded-0 col-md-5 text-right number3" maxlength="6"
                v-bind:id="'price_'+order_detail_index+'_'+t_expense_index"
                v-bind:name="'t_expense['+order_detail_index+']['+t_expense_index+'][price]'"
                v-bind:aria-describedby="'validation_server_price_'+order_detail_index+'_'+t_expense_index"
                v-model="t_expense_list[order_detail_index][t_expense_index]['price']"
                v-on:change="calc_net_income(order_detail_index)"
                v-on:keyup="validate_number(order_detail_index,t_expense_index,'price')"
                v-on:blur="onblured(order_detail_index,t_expense_index,'price')"
                v-on:focus="onfocused(order_detail_index,t_expense_index,'price')"
                />

                <button type="button" class="btn btn-danger col-md-2 d-print-none rounded-0"
                v-if="order_kbn != 3"
                v-on:click="remove_t_expense(order_detail_index,t_expense_index)"
                >
                <i class="fas fa-trash"></i>　削除
                </button>
            </div>


            <label for="" class="text-center border border-dark d-block mb-0 p-1 col-md-3 offset-md-2">売値</label>
            <input type="text" class="form-control border-dark rounded-0 col-md-5 text-right number3" maxlength="6"
            v-bind:id="'selling_price_'+order_detail_index"
            v-bind:name="'t_order_detail['+order_detail_index+'][selling_price]'"
            v-bind:aria-describedby="'validation_server_selling_price_'+order_detail_index"
            v-model="order_detail_list[order_detail_index]['selling_price']"
            v-on:change="calc_net_income(order_detail_index)"
            v-on:keyup="validate_number(order_detail_index,null,'selling_price')"
            v-on:blur="onblured(order_detail_index,null,'selling_price')"
            v-on:focus="onfocused(order_detail_index,null,'selling_price')"
            />

            <label for="" class="text-center border border-dark d-block mb-0 p-1 col-md-3 offset-md-2">実質手取</label>
            <input type="text" class="form-control border-dark rounded-0 col-md-5 text-right number3" maxlength="6" readonly
            v-bind:id="'net_income_'+order_detail_index"
            v-bind:name="'t_order_detail['+order_detail_index+'][net_income]'"
            v-bind:aria-describedby="'validation_server_net_income_'+order_detail_index"
            v-bind:value="order_detail_list[order_detail_index]['net_income']"
            />

            <!-- 末端価格のデフォルト値は売値なので v-bind:value -->
            <label for="" class="text-center border border-dark d-block mb-0 p-1 col-md-3 offset-md-2">末端売値</label>
            <input type="text" class="form-control border-dark rounded-0 col-md-5 text-right number3" maxlength="6"
            v-bind:id="'terminal_price_'+order_detail_index"
            v-bind:name="'t_order_detail['+order_detail_index+'][terminal_price]'"
            v-bind:aria-describedby="'validation_server_terminal_price_'+order_detail_index"
            v-bind:value="order_detail_list[order_detail_index]['selling_price']"
            v-on:keyup="validate_number(order_detail_index,null,'terminal_price')"
            v-on:blur="onblured(order_detail_index,null,'terminal_price')"
            v-on:focus="onfocused(order_detail_index,null,'terminal_price')"
            />
        </div>
    </div>

</template>

<script>
export default {
    name:'AddOrderDetailComponent',
    data:function(){
        return {
             merchandise_list        :this.merchandiseListInput
            ,m_expense_list          :this.mExpenseListInput
            ,order_detail_list       :this.orderDetailInput
            ,t_expense_list          :this.tExpenseInput
            ,defaultorderDetail      :this.defaultorderDetailInput
            ,order_kbn               :this.orderKbn
            ,selectedMerchandieseName:'' // 選択された商品名
        }
    },
    props:{
        merchandiseListInput       :[Object,Array], // 商品名リスト(プルダウンデータ)
        mExpenseListInput          :[Object,Array], // 経費名リスト(プルダウンデータ)
        orderDetailInput           :[Object,Array], // 注文詳細
        tExpenseInput              :[Object,Array], // 経費詳細
        //orderKbn                   :[Object,Array], // 1発注 2受注 3受注に紐づいた発注
        orderKbn                   :[Number], // 1発注 2受注 3受注に紐づいた発注
        defaultorderDetailInput    :[Object,Array]  // デフォルト値
    },
    computed: {

    },
    // 関数を定義する場所
    methods:{
        // 注文詳細の追加
        append_order_detail() {

            // order_detail_listのキーを配列で取得
            // mapでキー配列の各要素をNumber型にキャスト
            // apply 関数名.apply(関数の処理の対象となる要素,関数で使う引数(配列のみ) );
            // Math.maxは可変長引数で配列を渡すことはできないためapplyを使用
            // order_detail_listの最大番号に1を足してString型にキャストして返す
            var add_key = "0";
            if(this.order_detail_list.length > 0){
                add_key = (Math.max.apply(
                    null, Object.keys(this.order_detail_list).map(Number)
                ) + 1 ).toString();
            }

            //配列に空のオブジェクトを追加
            Vue.set(this.order_detail_list, add_key, {'order_id':add_key});
            Vue.set(this.t_expense_list, add_key, []);
        },
        // 注文詳細の削除
        remove_order_detail(order_detail_index,t_expense_index) {

            // order_detail_listから指定した番号の要素を削除
            Vue.delete(this.order_detail_list,order_detail_index);
            Vue.delete(this.t_expense_list,order_detail_index);
        },
        // 経費の追加
        append_t_expense(order_detail_index,order_detail_elem) {            
            var add_key;
            // 注文詳細に紐づく経費配列はあるが、それが空の場合
            if(this.t_expense_list[order_detail_index].length == 0)
            {
                add_key = "0";
            }
            // 注文詳細に紐づく経費配列があり1つ以上の場合
            else
            {
                add_key = (Math.max.apply(
                    null, Object.keys(this.t_expense_list[order_detail_index]).map(Number)
                ) + 1 ).toString();
            }

            //配列に空のオブジェクトを追加
            Vue.set(this.t_expense_list[order_detail_index], add_key,
                {
                    'order_id':order_detail_elem['order_id'],
                    'order_detail_id':order_detail_elem['order_detail_id']
                }
            );

        },
        // 経費の削除
        remove_t_expense(order_detail_index,t_expense_index) {

            // t_expense_listから指定した番号の要素を削除
            Vue.delete(this.t_expense_list[order_detail_index],t_expense_index);

            // 実質手取の計算
            this.calc_net_income(order_detail_index);

        },
        // 実質手取の計算
        calc_net_income: function (order_detail_index) {

            var purchase_price = 0; // 仕入価格
            var selling_price = 0; // 売値
            var expense = 0; // 経費

            if(this.order_detail_list[order_detail_index]['purchase_price'])
            { 
                var o = this.order_detail_list[order_detail_index]['purchase_price'];
                //console.log(o);
                if (o != null) {
                    purchase_price = Number(o.toString().replace(/,/g, ''));
                }                
            }

            if(this.order_detail_list[order_detail_index]['selling_price'])
            { 
                var o = this.order_detail_list[order_detail_index]['selling_price'];
                //console.log(o);
                if (o != null) {
                    selling_price = Number(o.toString().replace(/,/g, ''));
                }
                
            }

            // 経費の集計
            if(this.t_expense_list[order_detail_index])
            {
                var exp = this.t_expense_list[order_detail_index];

                for (let key in exp) {
                    if(exp[key]['price']) {
                        var o = exp[key]['price'];
                        //console.log(o);
                        expense += Number(o.toString().replace(/,/g, ''));
                    }
                }
            }

            var net_income = selling_price - (purchase_price + expense);

            if(isNaN(net_income))
            {
                net_income = '';
            }
            net_income = net_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // 実質手取りをセット
            Vue.set(this.order_detail_list[order_detail_index],'net_income',net_income);
            // return net_income;
        },

        calc_q2w: function (order_detail_index) {

            // 結果重量
            var w_val = 0;
            // 商品マスタの重さ
            var unit_w = this.order_detail_list[order_detail_index]['m_weight'];
            // 数量
            var q_val = this.order_detail_list[order_detail_index]['quantity'].replace(/[０-９]/g, function(s) {
                        return String.fromCharCode(s.charCodeAt(0) - 65248);
                    })
                    .replace(/[^0-9.(,)]/g, '');
            // 商品マスタの単位
            var unit_kbn = this.order_detail_list[order_detail_index]['unit_kbn'];
            // 係数
            var coefficient_val = 1;

            if(unit_kbn == 2){
                coefficient_val = 1000;
            }

            w_val = unit_w * q_val / coefficient_val;
            this.$set(this.order_detail_list[order_detail_index], 'weight', w_val.toLocaleString());
        },

        calc_w2q: function (order_detail_index) {

            // 結果数量
            var q_val = 0;
            // 商品マスタの重さ
            var unit_w = this.order_detail_list[order_detail_index]['m_weight'];
            // 重量
            var w_val = this.order_detail_list[order_detail_index]['weight'].replace(/[０-９]/g, function(s) {
                        return String.fromCharCode(s.charCodeAt(0) - 65248);
                    })
                    .replace(/[^0-9.(,)]/g, '');
            // 商品マスタの単位
            var unit_kbn = this.order_detail_list[order_detail_index]['unit_kbn'];
            // 係数
            var coefficient_val = 1;

            if(unit_kbn == 2){
                coefficient_val = 1000;
            }

            q_val = Math.ceil(w_val / unit_w * coefficient_val);
            this.$set(this.order_detail_list[order_detail_index], 'quantity', q_val.toLocaleString());

        },

        // 注文詳細の商品選択時
        select_merchandise: function(order_detail_index) {

            // 商品ID
            var merchandise_id = this.order_detail_list[order_detail_index]['merchandise_id'];

            // 商品リスト
            var mer  = this.merchandise_list

            for(let key in mer)
            {
                if(mer[key]['merchandise_id'] == merchandise_id)
                {

                    this.$set(this.order_detail_list[order_detail_index], 'm_weight', mer[key]['weight']);

                    this.$set(this.order_detail_list[order_detail_index], 'unit_kbn', mer[key]['unit_kbn']);

                    let unit_kbn = mer[key]['unit_kbn'];

                    switch (unit_kbn){
                    case 1:
                        this.$set(this.order_detail_list[order_detail_index], 'unit_name', 'kg');
                        break;
                    case 2:
                        this.$set(this.order_detail_list[order_detail_index], 'unit_name', 't');
                        break;
                    case 3:
                        this.$set(this.order_detail_list[order_detail_index], 'unit_name', '袋');
                        break;
                    case 4:
                        this.$set(this.order_detail_list[order_detail_index], 'unit_name', '缶');
                        break;
                    case 5:
                        this.$set(this.order_detail_list[order_detail_index], 'unit_name', '個');
                        break;
                    default:
                        
                    }

                    // 1.設定価格のデフォルト値は商品マスタの設定価格
                    var tmp_value = mer[key]['set_price'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    this.$set(this.order_detail_list[order_detail_index], 'set_price', tmp_value);

// 2.口銭デフォルト値をセット
                    tmp_value = "0";
                    this.$set(this.order_detail_list[order_detail_index], 'fare', tmp_value);

                    // 3.運賃見合いデフォルト値をセット
                    tmp_value = "0";
                    this.$set(this.order_detail_list[order_detail_index], 'freight', tmp_value);

                    // 4.仕入価格のデフォルト値は商品マスタの基本仕入価格
                    tmp_value = mer[key]['basic_purchase_unit_price'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    this.$set(this.order_detail_list[order_detail_index], 'purchase_price', tmp_value);

                    // 5.売値のデフォルト値は商品マスタの基本販売価格
                    tmp_value = mer[key]['basic_sales_unit_price'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    this.$set(this.order_detail_list[order_detail_index], 'selling_price', tmp_value);
                    
                    // 選択された商品名をセット
                    tmp_value = mer[key]['name'];
                    this.$set(this.order_detail_list[order_detail_index], 'merchandise_name', tmp_value);

                    // フレコンの時選択された紐付き紐無しをセット
                    tmp_value = "";
                    if(mer[key]['kind_id'] == 2){
                        tmp_value = mer[key]['twine_id'] == 2 ? '紐付き' : '紐無し';
                    }
                    this.$set(this.order_detail_list[order_detail_index], 'twine', tmp_value);

                    // 数量デフォルト値をセット
                    tmp_value = "0";
                    this.$set(this.order_detail_list[order_detail_index], 'quantity', tmp_value);

                    // 重量デフォルト値をセット
                    tmp_value = "0";
                    this.$set(this.order_detail_list[order_detail_index], 'weight', tmp_value);
                }
            }
            // 実質手取の計算
            this.calc_net_income(order_detail_index);
        },
        // 注文詳細の経費選択時
        select_expense: function(order_detail_index,t_expense_index) {

            // 選択された経費マスタID
            var m_expense_id = this.t_expense_list[order_detail_index][t_expense_index]['m_expense_id'];

            // 経費リスト
            var exp  = this.m_expense_list

            for(let key in exp)
            {
                if(exp[key]['expense_id'] == m_expense_id)
                {
                    // 経費マスタの金額をデフォルト値として使用
                    // this.t_expense_list[order_detail_index][t_expense_index]['price'] = exp[key]['price'];
                    this.$set(this.t_expense_list[order_detail_index][t_expense_index], 'price', exp[key]['price'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
                    // 印刷用の表示で使用
                    //this.t_expense_list[order_detail_index][t_expense_index]['expense_name'] = exp[key]['expense_name'];
                    this.$set(this.t_expense_list[order_detail_index][t_expense_index], 'expense_name', exp[key]['expense_name']);
                }
            }

            // 実質手取の計算
            this.calc_net_income(order_detail_index);
        },
        // フィルタ
        filter_texpense_list(order_detail_id){
            // t_expense_list(注文データに紐づく全経費データ)から注文詳細に紐づく経費データのみを抽出して配列にして返す
            var filtered_list = this.t_expense_list.filter(t_expense => t_expense['order_detail_id'] == order_detail_id);
            return filtered_list;
        },
        // 数値入力制限
        validate_number: function(order_detail_index,t_expense_index,column_name) {
            //console.log('validate_number:'+order_detail_index+','+t_expense_index+','+column_name);
            // 注文詳細
            if(t_expense_index === null)
            {
                var tmp_value = this.order_detail_list[order_detail_index][column_name];
                    tmp_value = tmp_value
                    .replace(/[０-９]/g, function(s) {
                        return String.fromCharCode(s.charCodeAt(0) - 65248);
                    })
                    .replace(/[^0-9.(,)]/g, '');
                if (tmp_value != null) {
                    //this.order_detail_list[order_detail_index][column_name] = tmp_value.replace(/[^0-9]/g,'');
                    tmp_value = tmp_value.replace(/[^0-9.]+/g,'');
                    if(isNaN(tmp_value))
                    {
                        tmp_value = '0';
                    }
                    this.$set(this.order_detail_list[order_detail_index], column_name, tmp_value);
                }
            }
            // 経費
            else
            {
                var tmp_value = this.t_expense_list[order_detail_index][t_expense_index][column_name];
                    tmp_value = tmp_value
                    .replace(/[０-９]/g, function(s) {
                        return String.fromCharCode(s.charCodeAt(0) - 65248);
                    })
                    .replace(/[^0-9.(,)]/g, '');
                if (tmp_value != null) {
                    tmp_value = tmp_value.replace(/[^0-9.]+/g,'');
                    if(isNaN(tmp_value))
                    {
                        tmp_value = '0';
                    }
                    //this.t_expense_list[order_detail_index][t_expense_index][column_name] = tmp_value.replace(/[^0-9]/g,'');
                    this.$set(this.t_expense_list[order_detail_index][t_expense_index], column_name, tmp_value);
                }
            }
        },
        // コンマ区切り
        onblured: function(order_detail_index,t_expense_index,column_name) {
            //console.log('onblured:'+order_detail_index+','+t_expense_index+','+column_name);
            
            // 注文詳細
            if(t_expense_index === null)
            {
                var tmp_value = this.order_detail_list[order_detail_index][column_name];
                tmp_value = tmp_value
                    .replace(/[０-９]/g, function(s) {
                        return String.fromCharCode(s.charCodeAt(0) - 65248);
                    })
                    .replace(/[^0-9.(,)]/g, '');
                //console.log(tmp_value);
                if (tmp_value != null) {
                    //this.order_detail_list[order_detail_index][column_name] = tmp_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    this.$set(this.order_detail_list[order_detail_index], column_name, Number(tmp_value).toLocaleString());
                }
            }
            // 経費
            else
            {
                var tmp_value = this.t_expense_list[order_detail_index][t_expense_index][column_name];
                tmp_value = tmp_value
                    .replace(/[０-９]/g, function(s) {
                        return String.fromCharCode(s.charCodeAt(0) - 65248);
                    })
                    .replace(/[^0-9.(,)]/g, '');
                //console.log(tmp_value);
                if (tmp_value != null) {
                    //this.t_expense_list[order_detail_index][t_expense_index][column_name] = tmp_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    this.$set(this.t_expense_list[order_detail_index][t_expense_index], column_name, Number(tmp_value).toLocaleString());
                }
            }
        },

        onfocused: function(order_detail_index,t_expense_index,column_name) {
            //console.log('onfocused:'+order_detail_index+','+t_expense_index+','+column_name);
            
            // 注文詳細
            if(t_expense_index === null)
            {
                var tmp_value = this.order_detail_list[order_detail_index][column_name];
                //console.log('onfocused:tmp_value:before:'+tmp_value);
                if (tmp_value != null) {
                    this.$set(this.order_detail_list[order_detail_index], column_name, tmp_value.toString().replace(/,/g, ''));
                    //console.log('onfocused:tmp_value:after:'+tmp_value.toString().replace(/,/g, ''));
                    //console.log('onfocused:---------:after:'+this.order_detail_list[order_detail_index][column_name]);
                }
                
            }
            // 経費
            else
            {
                var tmp_value = this.t_expense_list[order_detail_index][t_expense_index][column_name];
                //console.log('onfocused:tmp_value:'+tmp_value);
                if (tmp_value != null) {
                    // this.t_expense_list[order_detail_index][t_expense_index][column_name] = tmp_value.toString().replace(/,/g, '');
                    this.$set(this.t_expense_list[order_detail_index][t_expense_index], column_name, tmp_value.toString().replace(/,/g, ''));
                }
            }
        },
    }
};

</script>
