
/*
common-clear クリアボタン
common-save  保存ボタン
common-row   テーブル行
*/

// 行初期化処理
function ini_row()
{
    // 行ループ 全て初期化する
    $('.common-row').removeClass('bg-info');
}

// 入力フォームクリア
function clear_form()
{
    $('.common-form-input').each(function(){
        if($(this).data('defval')!== undefined){
            $(this).val($(this).data('defval'));
        }else{
            $(this).val('');
        }

    });
    $('input').removeClass('is-invalid');
}

// クリアボタンの動作
$('#common-clear').on('click', function(){

    // 入力項目ループ 全て初期化する
    clear_form();

    // 行初期化処理
    ini_row();

    var data = $(this).data('protect');

    // 削除ボタンの非表示
    $('#common-delete').addClass('d-none');

    // ボタンメッセージを変更
    if (data != 1) {
        $('#common-save').html('<i class="fas fa-save"></i>　登録'); 
        // 保存ボタンの色を変更
        $('#common-save').removeClass('btn-success');
        $('#common-save').addClass('btn-primary');

        $('#common-save').attr('data-target', '#common-confirm-save-modal');
    }

    // フラッシュメッセージを削除
    $('#flash-message').remove();

    // 更新時無効項目
    $('.upd-disable').prop('readonly', false);

})

// テーブル行クリックボタンの動作
$('.common-row').on('click', function(obj){

    // danyListに記述しているタグがクリックされた場合は処理しない
    danyList = ['a','button'];
    var tagName = obj.target.localName
    if(danyList.indexOf(tagName) > -1)
    {return;}

    // 選択行の値 JSONデータ
    var data = $(this).data('json');

    ini_row();
    // 選択行の色変更
    $(this).addClass('bg-info');

    // 入力項目ループ 全て初期化する
    clear_form();

    // 入力項目ループ
    $('.common-form-input').each(function(index,element){

        //TODO 初期化を入れるか後で考える

        // JSONデータのキーに入力項目のID属性と同じ値があれば
        // それを入力項目のvalue属性にセット
        var element_obj = $(element);

        if(data[element_obj.attr('id')]!== undefined)
        {
            //TODO テキストボックス セレクトボックス チェックボックス ラジオボタン 判定
            element_obj.val(data[element_obj.attr('id')]);
        }
    });

    // 保存ボタンの色を変更
    $('#common-save').removeClass('btn-primary');
    $('#common-save').addClass('btn-success');

    // 削除ボタンの表示
    $('#common-delete').removeClass('d-none');

    // ボタンメッセージを変更
    $('#common-save').html('<i class="fas fa-save"></i>　更新');
    $('#common-save').attr('data-target', '#common-confirm-update-modal');

    // フラッシュメッセージを削除
    $('#flash-message').remove();

    // 更新時無効項目
    $('.upd-disable').prop('readonly', true);
})

// 数値入力制限。（半角数字のみ）
$('.input-limit').on('input',function(e){
    let value = $(e.currentTarget).val();

    // このイコール謎、だがこれがないと動かない
    value = value
    .replace(/[０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
    })
    .replace(/[^0-9]/g, '');
    $(e.currentTarget).val(value);
})

// 数値入力制限。（半角数字カンマのみ）
$('.input-limit-kanma').on('input',function(e){
    let value = $(e.currentTarget).val();

    // このイコール謎、だがこれがないと動かない
    value = value
    .replace(/[０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
    })
    .replace(/[^0-9(,)]/g, '');
    $(e.currentTarget).val(value);
})

$('.input-limit-kanma').on('focus',function(e){
    let value = $(e.currentTarget).val();
    value = value.replace(/,/g, '');
    $(e.currentTarget).val(value);
})

$('.input-limit-kanma').on('focusout',function(e){
    let value = $(e.currentTarget).val();
    value = new Intl.NumberFormat().format(value);
    $(e.currentTarget).val(value);                
})

// 締日、支払いサイト桁数制限
$('.digits3').on('change',function(e){
    let value = $(e.currentTarget).val();
    if (value.length >= 3) {
        $(e.currentTarget).val(value.substr( 0, 3 ));
    }
})

// 予定数量桁数制限
$('.digits4').on('change',function(e){
    let value = $(e.currentTarget).val();
    if (value.length >= 4) {
        $(e.currentTarget).val(value.substr( 0, 4 ));
    }
})

// 伝票番号桁数入力制限
$('.digits5').on('change',function(e){
    let value = $(e.currentTarget).val();
    if (value.length >= 5) {
        $(e.currentTarget).val(value.substr( 0, 5 ));
    }
})

// 伝票番号桁数入力制限
$('.digits6').on('change',function(e){
    let value = $(e.currentTarget).val();
    if (value.length >= 6) {
        $(e.currentTarget).val(value.substr( 0, 6 ));
    }
})

//半角数字制限、3桁毎にコンマ挿入------------------------------------------------------------------
$('.number_constraint').on('focus', function (e) {
    var value = $(e.currentTarget).val();
    value = only_number(value);

    $(e.currentTarget).val(value);

    $(this).select();
});

$('.number_constraint').on('focusout', function (e) {
    var value = $(e.currentTarget).val();
    value = only_number(value);
    value = new Intl.NumberFormat().format(value);

    $(e.currentTarget).val(value);
}); 

$('.number_dec_constraint').on('focus', function (e) {
    var value = $(e.currentTarget).val();
    value = only_number_period(value);

    $(e.currentTarget).val(value);

    $(this).select();
});

$('.number_dec_constraint').on('focusout', function (e) {
    var value = $(e.currentTarget).val();
    value = only_number_period(value);

    value = Number(value).toLocaleString();
    $(e.currentTarget).val(value);
}); 

function only_number(input){
    input = input.replace(/[０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
    })
    input = input.replace(/[^0-9]+/g,"");
    
    return input;
}

function only_number_period(input){
    input = input.replace(/[０-９。]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
    })
    input = input.replace(/[^0-9.]+/g,"");
    
    return input;
}

//半角英数字制限------------------------------------------------------------------
$('.number_alpha_constraint').on('focus', function (e) {
    var value = $(e.currentTarget).val();
    value = only_number_alpha(value);
    if (value == 0) {
        value = '';
    }
    $(e.currentTarget).val(value);

    $(this).select();
});

$('.number_alpha_constraint').on('focusout', function (e) {
    var value = $(e.currentTarget).val();
    value = only_number_alpha(value);
    if (value == 0) {
        value = '';
    }
    $(e.currentTarget).val(value);
}); 

function only_number_alpha(input){
    input = input.replace(/[０-９ａ-ｚＡ-Ｚ]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
    })
    input = input.replace(/[^0-9a-zA-Z]+/g,"");
    
    return input;
}

//電話番号・FAX番号制限（数字＋ハイフン）------------------------------------------------------------------
$('.telephone_constraint').on('focus', function (e) {
    var value = $(e.currentTarget).val();
    value = only_telephone_number(value);
    if (value == 0) {
        value = '';
    }
    $(e.currentTarget).val(value);

    $(this).select();
});

$('.telephone_constraint').on('focusout', function (e) {
    var value = $(e.currentTarget).val();
    value = only_telephone_number(value);
    if (value == 0) {
        value = '';
    }
    $(e.currentTarget).val(value);
}); 

function only_telephone_number(input){
    //input = input.replace(/[^0-9]+/g,"");
    input = input.replace(/[\₋\―\ー\‐\－\～\—]+/g,"-");
    input = input.replace(/[０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
    })
    input = input.replace(/[^0-9/-]+/g,"");
    
    return input;
}

//帳票index画面：半角数字制限、コンマ無し。------------------------------------------------------------------
$('.ym_constraint').on('focus', function (e) {
    var value = $(e.currentTarget).val();
    value = only_number(value);
    if (value == 0) {
        value = '';
    }
    $(e.currentTarget).val(value);

    $(this).select();
});

$('.ym_constraint').on('focusout', function (e) {
    var value = $(e.currentTarget).val();
    value = only_number(value);
    if (value.length >4) {
        value = value.substr(0,4) + '/' + value.substr(4)
    }
        
    if (value == '0') {
        value = '';
    }
    
    $(e.currentTarget).val(value);
}); 

//------------------------------------------------------------------
$('.common-form-input').on('click', function(obj){
    $(this).select();
});